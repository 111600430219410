<template>
  <div class="cont_MostrarMenuShopping">
    <i class="fa-solid fa-xmark cursor-pointer" @click="setMostrarMenuShopping()"></i>
  </div>
  <div class="cont_menuShopping_main">
    <router-link class="cont_Logo_menu" :to="'/'">
      <img src="/img/JB/JB_Logo_Blanco.webp" alt="">
    </router-link>
    <div class="seccion">
      <ul class="list_opcionesMenu text-left">
        <li>
          <router-link class="op_menu" to="/DatosPersonales">
            <i class="fa-solid fa-user border rounded-xl py-1 px-2 scale-125 mr-4 flex flex-col items-center justify-center w-[30px] h-[30px]"></i>
            <span class="font-semibold text-sm">Mi Cuenta y Datos Personales</span>
          </router-link>
        </li>
        <li>
          <router-link class="op_menu" to="/Facturas">
            <i class="fa-solid fa-file-invoice-dollar border rounded-xl py-1 px-2 scale-125 mr-4 flex flex-col items-center justify-center w-[30px] h-[30px]"></i>
            <span class="font-semibold text-sm">Mis Facturas y Cambios o Devoluciones</span>
          </router-link>
        </li>
        <li class="pb-2">
          <a class="op_menu font-semibold text-sm" :href="'https://catalogovirtual.juanabonita.com.co/' + CampLL" target="_blank"><i class="fa-solid fa-book-open border rounded-xl p-1 scale-125 mr-4 flex flex-col items-center justify-center w-[30px] h-[30px]"></i><span>Ver Catálogo Virtual</span></a>
        </li>
      </ul>
    </div>
    <div class="seccion">
      <span class="TituloGZ text-xs 2xl:text-base">GERENTE DE ZONA</span>
      <div class="cont_datosGZ_menu text-xs 2xl:text-base">
        <span class="NombreGZ text-xs 2xl:text-base">
          {{  
            infoCliente.IdUbicacionGeograficaNavigation[0].IdZonaNavigation.PnombreGerente + " " + 
            infoCliente.IdUbicacionGeograficaNavigation[0].IdZonaNavigation.SnombreGerente + " " + 
            infoCliente.IdUbicacionGeograficaNavigation[0].IdZonaNavigation.PapellidoGerente + " " + 
            infoCliente.IdUbicacionGeograficaNavigation[0].IdZonaNavigation.SapellidoGerente 
          }}
        </span>
        <div class="cont_detallesGZ_menu">
          <ul>
            <li class="flex flex-row justify-start items-center align-top text-left text-xs 2xl:text-base">
              <i class="fa-solid fa-map-location-dot pr-2"></i> <div class=" relative block">{{ infoCliente.IdUbicacionGeograficaNavigation[0].IdZonaNavigation.CodigoZona }}</div>
            </li>
            <li class="flex flex-row justify-start items-center align-top text-left text-xs 2xl:text-base">
              <i class="fa-solid fa-mobile-screen pr-2"></i> <div class=" relative block">{{ infoCliente.IdUbicacionGeograficaNavigation[0].IdZonaNavigation.CelularGerente }}</div>
            </li>
            <li class="flex flex-row justify-start items-center align-top text-left text-xs 2xl:text-base">
              <i class="fa-solid fa-envelope pr-2"></i> <div class=" relative block">{{ "ger.zona" + infoCliente.IdUbicacionGeograficaNavigation[0].IdZonaNavigation.CodigoZona.substr(1,3) + "@juanabonita.com.co" }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="seccion cont_RRSS text-xs 2xl:text-base">
      <div class="inline-table ">Síguenos</div>
      <a href="https://www.facebook.com/Juanabonitacolombia/" target="_blank">
        <i class="fa-brands fa-facebook-f"></i>
      </a>
      <a href="https://www.instagram.com/juanabonitacolombia/" target="_blank">
        <i class="fa-brands fa-instagram"></i>
      </a>
      <a href="https://www.youtube.com/channel/UCCSIV-WKjF6TrT57Kvd59ag" target="_blank">
        <i class="fa-brands fa-youtube"></i>
      </a>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "@vue/runtime-core";
import { useStore } from 'vuex';
export default{
  name: "MenuShopping",
  setup(){
    const store = useStore()
    const infoCliente = computed(() => store.state.dataUsuario.infoCliente)
    const CampLL = computed(() => store.state.dataUsuario.infoCliente.CampañaIngresoMicrocolsa)
    

    const setMostrarMenuShopping = () => {
      store.state.mostrarMenuShopping = !store.state.mostrarMenuShopping
    }
    ////console.log(store.state.dataUsuario.infoCliente[0])
    return{
      infoCliente,
      CampLL,
      setMostrarMenuShopping,
    }
  }
}
</script>

<style scoped>
.cont_menuShopping_main {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  transition: .3s ease-in-out;
}

.seccion {
  padding: 0.4rem 0rem;
  border-bottom: 0.1rem solid #fff;
  width: 100%;
}

.cont_Logo_menu {
  display: block;
  margin: auto;
  width: 80%;
}

.cont_imgPerfil_menu {
  border-radius: 100%;
  overflow: hidden;
  width: 40%;
  margin: 0.5rem auto;
}

.cont_datosCliente_menu span {
  display: block;
}

.TipoCliente {
  font-weight: 700;
}

.NombreCliente {
  font-weight: 600;
}

.TituloGZ {
  display: block;
  font-weight: 700;
}

.NombreGZ {
  display: block;
}

.cont_detallesGZ_menu ul {
  width: 85%;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.cont_detallesGZ_menu ul li {
  margin: 0.2rem 0rem;
}

.list_opcionesMenu {
  width: 80%;
  margin: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: nowrap;
  flex-direction: column;
}
.list_opcionesMenu li {
  margin: 0.3rem 0rem;
}
.list_opcionesMenu li:hover span {
  text-decoration: underline;
}

.op_menu {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
}

.cont_MostrarMenuShopping {
  position: absolute;
  top: 2%;
  left: 9%;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
}

.cont_MostrarMenuShopping i {
  scale: 1.5;
}
.cont_RRSS {
  display: flex;
  color: #fff;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
  padding: 1rem 2rem;
  height: 1rem;
}
.cont_RRSS a {
  width: 1rem;
  display: block;
  cursor: pointer;
  margin: 0px 0.5rem;
}
.cont_RRSS a svg {
  width: 100%;
}
</style>