<template>
  <div class="select-none cont_popUpClientes flex items-start justify-center flex-wrap flex-row-reverse w-[90vw] xl:w-[60vw] " >
    <div class="select-none cont_btnCerrarPopUpClientes cursor-pointer relative left-[-5rem] top-[0.5rem] md:left-[-7rem] md:top-[1rem] z-[99] flex justify-center items-center align-middle border-2 border-red-600 text-red-600 bg-white rounded-full w-[1.5rem] h-[1.5rem] md:w-[2rem] md:h-[2rem] ml-1" @click="cerrarPopUp()">
      <i class="select-none fa-solid fa-xmark scale-100 md:scale-150"></i>
    </div>
    <div class="select-none absolute mt-[72%] flex md:mt-[62%] xl:mt-[37%]">
      <div class="SeleccionImagenPopUp inline-block relative w-[1rem] h-[1rem] rounded-full mx-[0.25rem] transition-all duration-500 ease-in-out z-[99]" :class="index == ImagenActualCarrousel ? 'bg-[#f93a5a] cursor-not-allowed scale-125' : 'bg-white cursor-pointer scale-100'" v-for="(producto, index) in ProductosPopUp" :key="index" @click="AccionControlCarrousel(index,false)"></div>
    </div>
    <div class="select-none cont_carrousel flex flex-wrap flex-row content-center justify-center items-center">
      <div class="select-none block z-[99] mr-[1rem] w-[1rem] md:w-[2rem]">
        <div class="select-none" :class="ImagenActualCarrousel > 0 ? 'cursor-pointer' : 'hidden cursor-not-allowed'" @click="ImagenActualCarrousel > 0 ? AccionControlCarrousel(-1,true) : ''" :disabled="ImagenActualCarrousel > 0 ? 'true' : 'false'">
          <svg class="select-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 220">
            <defs class="select-none"></defs>
            <path class="select-none" style="fill: #fff;" d="M 40 0 L 60 0 L 20 110 L 60 220 L 40 220 L 0 110 L 40 0 Z"></path>
          </svg>
        </div>
      </div>
      <div class="select-none overflow-hidden rounded-3xl">
        <div class="select-none CintaCarrousel transition-all duration-500 ease-in-out flex flex-wrap flex-col justify-center items-center relative w-[70vw] h-[70vw] md:w-[60vw] md:h-[60vw] xl:w-[35vw] xl:h-[35vw] shadow-[0rem_1rem_2rem_-0.5rem_#00000080] cursor-pointer" style="right: 0px;">
          <div class="select-none block cursor-pointer" v-for="(producto, index) in ProductosPopUp" :key="index" @click="agregarAlCarrito(index)">
            <img :class="'img_' + producto.CodigoProducto" :src="'https://juanabonitavirtual.com.co/img/PopUp/' + Campana + '/' + producto.CodigoProducto + '.webp'" alt="">
          </div>
        </div>  
      </div>
      <div class="select-none block z-[99] ml-[1rem] w-[1rem] md:w-[2rem] text-white">
        <div :class="ImagenActualCarrousel < (ProductosPopUp.length - 1) ? 'cursor-pointer' : 'hidden cursor-not-allowed'" @click="ImagenActualCarrousel < (ProductosPopUp.length - 1) ? AccionControlCarrousel(1,true) : ''" :disabled="ImagenActualCarrousel < (ProductosPopUp.length - 1) ? 'true' : 'false'">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 220">
            <defs></defs>
            <path style="fill: #fff;" d="M 0 0 L 20 0 L 60 110 L 20 220 L 0 220 L 40 110 L 0 0 Z"></path>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from '@/router';
import { computed, ref } from "@vue/runtime-core";
import { watch } from 'vue';
import { useStore } from 'vuex';

export default {
    name: 'PopUpClientes',
    setup(){
        const store = useStore()
        
        const cerrarPopUp = () => {
          store.state.mostrarPopUpClientes = false
        }
        const dataUsuario = computed(() => store.state.dataUsuario)
        const infoCliente = computed(() => store.state.dataUsuario.infoCliente)
        const Campana = ref("")
        const leaderList = computed(() => store.state.leaderList)
        const PopUpMostrado = computed(() => store.state.PopUpMostrado)
        const productosCarrito = computed(() => store.state.productosCarrito.filter((el) => {return el.Eliminado == false}))
        const ProductosPopUp = computed(() => leaderList.value.filter((el) => {return el.Pagina == 1 }))
        const mostrarPopUpClientes = computed(() => store.state.mostrarPopUpClientes)
        const productoDigitado = ref([])
        const TotalPedidoCatalogo = computed(() => productosCarrito.value.filter((el) => {return el.Pagina != 0 && el.idTipoProducto != 4}).filter((el) => {return el.Eliminado == false}).reduce((acumulador, objeto)=>{
          return acumulador + (objeto.Cantidad * objeto.PrecioCatalogo)
        }, 0))
        const ValorPedidoMinimoCatalogo = computed(() => store.state.ValorPedidoMinimoCatalogo)
        const MedidasImgProductos = ref([])
        const ImagenActualCarrousel = ref(0)
        console.log(ProductosPopUp.value)

        const EsperarElementoDOM = async (Seleccionador) => {
          return new Promise(resolve => {
            if (document.querySelector(Seleccionador)) {
              return resolve(document.querySelector(Seleccionador));
            }
            const Observador = new MutationObserver(Mutaciones => {
              if (document.querySelector(Seleccionador)) {
                Observador.disconnect();
                resolve(document.querySelector(Seleccionador));
              }
            });
            Observador.observe(document.body, {
              childList: true,
              subtree: true
            });
          });
        }

        const setMedidasImgProductos = (objProductosPopUp) => {
          MedidasImgProductos.value = []
          objProductosPopUp.forEach(async (el, index) => {
            var elemento = await EsperarElementoDOM(".img_" + el.CodigoProducto)
            var objMedidasImgProductos = {
              Codigo: el.CodigoProducto,
              MedidaAncho: elemento.width
            }
            MedidasImgProductos.value.push(objMedidasImgProductos)
          });
          console.log(MedidasImgProductos.value)
        }

        watch(mostrarPopUpClientes, (N,V) => {
          if(N == true){
            setMedidasImgProductos(ProductosPopUp.value)
          }
        })

        const AccionControlCarrousel = (Valor,Flecha) => {
          if(Flecha){
            ImagenActualCarrousel.value = ImagenActualCarrousel.value + Valor
          }else{
            ImagenActualCarrousel.value = Valor
          }
        }

        watch(ImagenActualCarrousel, (N,V) => {
          var PasosCiclo = Math.abs(N - V)
          console.log(N, V, (N - V))
          for (let index = 0; index < PasosCiclo; index++) {
            var MedidaAncho = MedidasImgProductos.value[V].MedidaAncho
            var OffsetActual = Number(document.querySelector(".CintaCarrousel").style.right.replace('px',''))
            if((N - V) < 0){
              document.querySelector(".CintaCarrousel").style.right = (OffsetActual - MedidaAncho).toString() + 'px'
              console.log((OffsetActual - MedidaAncho))
            }else{
              document.querySelector(".CintaCarrousel").style.right = (OffsetActual + MedidaAncho).toString() + 'px'
              console.log((OffsetActual + MedidaAncho))
            }
          }
        })

        const agregarAlCarrito = (IndexImagen) => {
          productoDigitado.value = leaderList.value.filter((el) => {return el.Pagina == 1 && el.CodigoProducto == MedidasImgProductos.value[IndexImagen].Codigo })
          var cantidad = 1
          var alertaBazar = false
          var alertaPopUp = false
          var alertaJV = false
          var ProductoAgregado = false
          var CantidadExcedida = false
          productoDigitado.value.forEach((el) => {
            if(TotalPedidoCatalogo.value < ValorPedidoMinimoCatalogo.value){
              if(el.idTipoProducto == 4 && el.Pagina != 1){
                alertaBazar = true
              }
              if(el.Pagina == 1){
                alertaPopUp = true
              }
              if(el.Pagina == 0){
                alertaJV = true
              }
            }
            if(alertaBazar == false && alertaJV == false && alertaPopUp == false){
              if(el.CodigoProducto != undefined){
                if(PopUpMostrado.value == false){
                  store.state.mostrarPopUpClientes = true
                  store.state.PopUpMostrado = true
                }
                var consultaCarrito = store.state.productosCarrito.filter((ele) => {return  ele.CodigoProducto == el.CodigoProducto})
                var consultaCarritoIndex = store.state.productosCarrito.map(e => e.CodigoProducto).indexOf(el.CodigoProducto)
                var PrecioCatalogo = el.PrecioCatalogo
                var PrecioCliente = el.PrecioCliente
                var idTipoFacturacion = infoCliente.value.IdTipoFacturacion
                var Descuento = 0
                if(idTipoFacturacion != 1){
                  if(idTipoFacturacion == 3 || infoCliente.value.Logesempleado == true){
                    if((el.idTipoProducto == 1) || (el.idTipoProducto == 14)){
                      if((el.Descripcion.toUpperCase().indexOf("DENIM") >= 0) || (el.idTipoProducto == 14)) {
                        Descuento = 40.00
                      }else{
                        Descuento = 50.00
                      }
                    }else{
                      if (el.idTipoProducto == 15){
                        Descuento = 40.00
                      }
                      if (el.idTipoProducto == 16){
                        Descuento = 40.00
                      }
                    }
                    PrecioCliente = Math.round(PrecioCatalogo * (100.00 - Descuento)/100.00)
                  }
                  if(idTipoFacturacion == 2){
                    if(el.idTipoProducto == 1 || (el.idTipoProducto == 14)){
                      if((el.Descripcion.toUpperCase().indexOf("DENIM") >= 0) || (el.idTipoProducto == 14)) {
                        Descuento = 15.00
                      }else{
                        Descuento = 28.00
                      }
                    }else{
                      if (el.idTipoProducto == 15){
                        Descuento = 15.00
                      }
                      if (el.idTipoProducto == 16){
                        Descuento = 10.00
                      }
                    }
                    PrecioCliente = Math.round(PrecioCatalogo * (100.00 - Descuento)/100.00)
                  }
                }
                if(consultaCarrito.length == 0){
                  var dataTemp = {
                    "PosicionMenuGenero": el.PosicionMenuGenero,
                    "Genero": el.Genero,
                    "Categoria": el.Categoria,
                    "Grupo": el.Grupo,
                    "Talla": el.Talla,
                    "Color": el.Color,
                    "Pagina": el.Pagina,
                    "Referencia": el.Referencia,
                    "CodigoProducto": el.CodigoProducto,
                    "Descripcion": el.Descripcion,
                    "PrecioCatalogo": PrecioCatalogo,
                    "PrecioCliente": PrecioCliente,
                    "idTipoProducto": el.idTipoProducto,
                    "Cantidad": cantidad,
                    "Eliminado": false,
                    "sumaPuntos": el.sumaPuntos
                  }
                  store.state.productosCarrito.push(dataTemp)
                  ProductoAgregado = true
                }else{
                  if(store.state.productosCarrito[consultaCarritoIndex].Eliminado == true){
                    ProductoAgregado = true
                    store.state.productosCarrito[consultaCarritoIndex].Eliminado = false
                    store.state.productosCarrito[consultaCarritoIndex].Cantidad = 1
                  }
                  else{
                    if(store.state.productosCarrito[consultaCarritoIndex].Cantidad == 9){
                      CantidadExcedida = true
                    }
                    else{
                      store.state.productosCarrito[consultaCarritoIndex].Cantidad = store.state.productosCarrito[consultaCarritoIndex].Cantidad + 1
                    }
                  }
                }
              }
            }
          })
          if(alertaBazar == false && alertaJV == false && alertaPopUp == false){
            localStorage.setItem("PedidoCliente", JSON.stringify(store.state.productosCarrito));
            if(ProductoAgregado == false){
              if(CantidadExcedida == true){
                Swal.fire({
                  position: 'bottom-end',
                  icon: 'error',
                  title: 'CANTIDAD EXCEDIDA!',
                  text: 'NO SE PUEDEN PEDIR MAS DE 9 UNIDADES DE UN MISMO PRODUCTO',
                  showConfirmButton: false,
                  timer: 3000
                })
              }else{
                Swal.fire({
                  position: 'bottom-end',
                  icon: 'warning',
                  title: 'PRODUCTO YA SE ENCUENTRA EN EL CARRITO!',
                  text: 'SE ACTUALIZARAN LAS CANTIDADES',
                  showConfirmButton: false,
                  timer: 3000
                })
              }
            }else{
              Swal.fire({
                position: 'bottom-end',
                icon: 'success',
                title: 'PRODUCTO AGREGADO CORRECTAMENTE!',
                showConfirmButton: false,
                timer: 2000
              })
            }
          }else{
            if(alertaBazar){
              Swal.fire({
                position: 'bottom-end',
                icon: 'error',
                title: 'NO CUMPLES CON TU PEDIDO MÍNIMO!',
                text: 'RECUERDA QUE BAZAR NO SUMA PARA PEDIDO MÍNIMO, PRIMERO DEBES PEDIR PRODUCTOS DE CATÁLOGO HASTA CUMPLIR EL PEDIDO MÍNIMO',
                showConfirmButton: false,
                timer: 5000
              })
            }else{
              if(alertaPopUp){
                Swal.fire({
                  position: 'bottom-end',
                  icon: 'error',
                  title: 'NO CUMPLES CON TU PEDIDO MÍNIMO!',
                  text: 'RECUERDA QUE LA OFERTA DE ÚLTIMO MINUTO NO SUMA PARA PEDIDO MÍNIMO, PRIMERO DEBES PEDIR PRODUCTOS DE CATÁLOGO HASTA CUMPLIR EL PEDIDO MÍNIMO',
                  showConfirmButton: false,
                  timer: 5000
                })
              }else{
                Swal.fire({
                  position: 'bottom-end',
                  icon: 'error',
                  title: 'NO CUMPLES CON TU PEDIDO MÍNIMO!',
                  text: 'RECUERDA QUE JUANA VIRTUAL NO SUMA PARA PEDIDO MÍNIMO, PRIMERO DEBES PEDIR PRODUCTOS DE CATÁLOGO HASTA CUMPLIR EL PEDIDO MÍNIMO',
                  showConfirmButton: false,
                  timer: 5000
                })
              }
            }
          }
        }

        const setCampana = () => {
          Campana.value = dataUsuario.value.infoCliente.CampañaIngresoMicrocolsa
          //console.log(dataUsuario.value.infoCliente.CampañaIngresoMicrocolsa)
        }

        watch(dataUsuario, (N,V) => {
          if(N.infoCliente != undefined){
            setCampana()
          }
        })

        if(dataUsuario.value.infoCliente != undefined){
          setCampana()
        }

        watch(dataUsuario, (N,V) => {
          //console.log(N)
          if(N.length != undefined || N.length > 0){
            setCampana()
          }
        })

        const seleccionarProducto = () => {
          cerrarPopUp()
          store.state.generoSeleccionado = {Genero: undefined, index: 0}
          store.state.categoriaSeleccionada = {Categoria: undefined, index: 0}
          store.state.grupoSeleccionado = {Grupo: undefined, index: 0}
          store.state.precioSeleccionado = {Precio: undefined, index: 0}
          store.state.referenciaSeleccionada = {Referencia: undefined, index: 0}
          store.state.paginaSeleccionada = {Pagina: 1, index: 0}
          router.push("/Seleccion")
        }

        return{
          cerrarPopUp,
          Campana,
          seleccionarProducto,
          ProductosPopUp,
          AccionControlCarrousel,
          ImagenActualCarrousel,
          agregarAlCarrito
        }
    }
}
</script>

<style>

</style>