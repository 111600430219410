<template>
  <div class="ModalCargando">
    <div class="SpritesModalCargando"></div>
  </div>
</template>

<script>
import { computed, defineComponent } from '@vue/runtime-core'
export default defineComponent ({
  name: "ModalCargando",
  setup(){
  }
})
</script>

<style>
.SpritesModalCargando {
  background: url(https://juanabonitavirtual.com.co/img/JB/Logo_JB_Sprites.webp);
  width: 200px;
  height: 200px;
  animation: AnimacionLogoJB 1s steps(41) infinite;
}

@keyframes AnimacionLogoJB {
  from {
    background-position: -10px;
  }
  to {
    background-position: -9030px;
  }
}
</style>